<template>
  <div class="result-view">
    <div class="nav">
      <h2>
        <span class="back" v-on:click="backToHistory()"
          >&lt;&nbsp;&nbsp;返回</span
        >
      </h2>
    </div>
    <div class="re_container">
      <div class="re_sec d-flex flex-wrap">
        <div class="w-100 text-center py-3">
          <div
            class="heading5 fw-bold d-flex justify-content-center align-items-center gap-2"
          >
            <span data-name="name">{{ result.name }}</span>
            <span>/</span>
            <span data-name="gender">
              {{ result.gender == 1 ? '男' : '女' }}
            </span>
          </div>
          <p class="paragraph mt-3 text-buy">
            阳历
            <span class="time g-text-dark">
              {{ getMoment(result.birthday).year() }} 年
              {{ getMoment(result.birthday).month() + 1 }} 月
              {{ getMoment(result.birthday).date() }} 日
            </span>
            <span class="">{{
              getSolarHour(getMoment(result.birthday).hour())
            }}</span>
          </p>
          <p class="paragraph text-buy">
            农历
            <span class="time g-text-dark">
              {{ result.lunarBirthday.year }} 年
              {{ result.lunarBirthday.month }} 月
              {{ result.lunarBirthday.day }} 日
              {{ result.lunarBirthday.shiChen }} 时</span
            >
          </p>
        </div>
        <div class="w-100 border-top text-center py-4">
          <h6 class="heading6">订单编号 {{ orderNo }}</h6>
        </div>
      </div>
      <!-- 1 -->
      <div class="re_sec">
        <div class="title_area d-flex flex-column justify-content-center gap-4">
          <div class="re_sec_img">
            <img
              v-if="result.gender == 1"
              src="@/assets/image/love-woman.png"
              alt=""
            />
            <img v-else src="@/assets/image/love-man.png" alt="" />
            <h4 class="img_title">我的真命指南</h4>
          </div>
          <p class="text-center">
            茫茫人海真命何处寻，锁定真命天子的特征，在对的方向找到对的人。
          </p>
        </div>
        <!-- 1-1 -->
        <div class="result_box masonry">
          <div class="w-50 px-sm-5 item">
            <h3 class="re_subtitle">伴侣特质</h3>
            <div class="d-flex gap-4 pb-5">
              <p class="mx-auto mx-sm-0">
                {{ result.matePersonality.join('、') }}
              </p>
            </div>
          </div>
          <div class="w-50 px-sm-5 item">
            <h3 class="re_subtitle">外表特征</h3>
            <div class="d-flex gap-4 pb-5">
              <p class="mx-auto mx-sm-0">
                {{ result.mateAppearance.join('、') }}
              </p>
            </div>
          </div>
          <div class="w-50 px-sm-5 item">
            <h3 class="re_subtitle">人格特质</h3>
            <div class="d-flex gap-4 pb-5">
              <p class="mx-auto mx-sm-0">
                {{ result.personality.join('、') }}
              </p>
            </div>
          </div>
          <div class="w-50 px-sm-5 item">
            <h3 class="re_subtitle">姓氏</h3>
            <div class="d-flex gap-4 pb-5">
              <p class="mx-auto mx-sm-0">
                {{ result.lastName.join('、') }}
              </p>
            </div>
          </div>
          <div class="w-50 px-sm-5 item">
            <h3 class="re_subtitle">年龄差距</h3>
            <div class="d-flex gap-4 pb-5">
              <p class="mx-auto mx-sm-0">
                {{ result.ageGap.join('、') }}
              </p>
            </div>
          </div>
          <div class="w-50 px-sm-5 item">
            <h3 class="re_subtitle">真命出现年份</h3>
            <div class="d-flex gap-4 pb-5">
              <p class="mx-auto mx-sm-0">
                {{
                  result.trueLoveYear.length
                }}位真命天子/天女，将会在你人生的精华时期出现，其中分别是：
                <span data-name="trueLoveYear">
                  {{ result.trueLoveYear.join('、') }}
                </span>
                年他将出现在您的生命中。
              </p>
            </div>
          </div>
          <div class="w-50 px-sm-5 item">
            <h3 class="re_subtitle">相似明星</h3>
            <div class="d-flex gap-4 pb-5">
              <p class="mx-auto mx-sm-0">
                {{ result.similarStar }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- 2 -->
      <div class="re_sec">
        <div class="title_area d-flex flex-column justify-content-center gap-4">
          <div class="re_sec_img">
            <img
              v-if="result.gender == 1"
              src="@/assets/image/love-woman.png"
              alt=""
            />
            <img v-else src="@/assets/image/love-man.png" alt="" />
            <h4 class="img_title">真命个性分析</h4>
          </div>
          <p class="text-center">
            要怎么跟我的真命好好相处呢？他会是什么个性的人呢？是温暖善良，还是聪明过人呢？
          </p>
        </div>
        <!-- 1-1 -->
        <div class="result_box">
          <div class="w-100 px-sm-5">
            <h3 class="re_subtitle text-center">
              透过你的星盘发现，<br />你的对象的命盘是{{ result.xingZuo }}星
            </h3>
            <div class="d-flex justify-content-center">
              <div v-for="(card, index) in cards" :key="index">
                <img :src="'/img/star/' + card + '.jpeg'" />
              </div>
            </div>
          </div>
          <div class="px-sm-5">
            <h3 class="re_subtitle text-center">
              {{ result.xingZuo }}星性格特质详解
            </h3>
            <div class="d-flex gap-4">
              <ul class="mx-auto personality px-0">
                <li
                  class="intro"
                  v-for="(item, index) in result.personalityItemExplain"
                  :key="index"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- 3 -->
      <div class="re_sec">
        <div class="title_area d-flex flex-column justify-content-center gap-4">
          <div class="re_sec_img">
            <img src="/image/truelove/love-img03.png" alt="" />
            <h4 class="img_title">我的爱情运势</h4>
          </div>
          <p class="text-center">
            单身的你何时能顺利脱单，脱单之后又该如何好好经营一段好的姻缘，安然渡过爱情的起起落落。
          </p>
        </div>
        <!-- 3-1 -->
        <div class="result_box">
          <div class="w-100 border-bottom">
            <h3 class="re_subtitle text-center">婚前感情状态</h3>
            <div class="desc-item-content">
              <p class="mx-auto">
                {{ result.beforeMarried }}
              </p>
            </div>
          </div>
          <div class="w-100 border-bottom">
            <h3 class="re_subtitle text-center">恋爱失败的原因</h3>
            <div class="desc-item-content">
              <p class="mx-auto">
                {{ result.failReason }}
              </p>
            </div>
          </div>
          <div class="w-100 border-bottom">
            <h3 class="re_subtitle text-center">婚后生活</h3>
            <div class="desc-item-content">
              <p class="mx-auto">
                {{ result.afterMarried }}
              </p>
            </div>
          </div>
          <div class="w-100 border-0">
            <h3 class="re_subtitle text-center">适合你的爱情经营秘诀</h3>
            <div class="desc-item-content">
              <p class="mx-auto">
                {{ result.loveIdea }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- 4 -->
      <div class="re_sec">
        <div class="title_area d-flex flex-column justify-content-center gap-4">
          <div class="re_sec_img">
            <img src="/image/truelove/love-img04.png" alt="" />
            <h4 class="img_title">我的爱情御守</h4>
          </div>
          <p class="text-center">
            我应该做哪些努力，让幸福到手，感情运势大吉， 守护我的美满人生。
          </p>
        </div>
        <!-- 4-1 -->
        <div class="result_box">
          <div class="w-100 border-bottom">
            <h3 class="re_subtitle text-center">幸福建议</h3>
            <div class="desc-item-content">
              <p class="mx-auto">
                {{ result.happySuggestion }}
              </p>
            </div>
          </div>
          <div class="w-100 border-bottom">
            <h3 class="re_subtitle text-center">给你的开运建议</h3>
            <div class="desc-item-content">
              <p class="mx-auto">
                爱情的来临需要时间跟缘份，对于感情，要更细心敏锐一些，如果单身，多留意自己周围欣赏的对象，有机会就和对方多交流，相互了解，若能情投意合，就尝把握交往看看。
              </p>
              <p class="mx-auto">
                如果你已经有交往对象，就理性相处，遇到相处问题多沟通，爱情最令人心动的就是在一起体验人生的美好，不论结果如何，都让自己在恋爱的过程中，开心幸福！
              </p>
            </div>
          </div>
          <div class="w-100 border-0">
            <h3 class="re_subtitle text-center">适合你的爱情经营秘诀</h3>
            <div class="desc-item-content">
              <p class="mx-auto">
                {{ result.loveIdea }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import moment from 'moment-timezone'

import { mapGetters } from 'vuex'
import { SHI_CHEN } from '@/constants/shi-chen-list'

export default {
  name: 'ResultView',
  data() {
    return {
      result: { xingZuo: '' },
      activeLeft: true,
    }
  },
  async beforeRouteEnter(to, from, next) {
    if (!store.getters.isLogin) {
      let res = await store.dispatch('login', {
        ...to.query,
        path: to.path,
        channel: store.state.fortune.channel,
      })
      if (!res) {
        next({ name: 'Home' })
      }
    }

    if (
      'undefined' === typeof store.getters['fortune/search'](to.params.orderNo)
    ) {
      var payload = {
        ttxid: store.state.user.ttxid,
        token: store.state.user.token,
        timestamp: store.state.user.timestamp,
        orderNo: to.params.orderNo,
      }
      store.dispatch('fortune/getResult', payload).then(
        () => {
          next()
        },
        (err) => {
          alert('尚未支付')
          next({ name: 'Home' })
        }
      )
    } else {
      next()
    }
  },
  created: function () {
    this.result = { ...this.search(this.$route.params.orderNo) }
    this.result.lowMonth =
      this.result.future &&
      this.result.future.reduce(
        (lowFuture, future) =>
          future.level < lowFuture.level ? future : lowFuture,
        this.result.future[0]
      )
  },
  mounted: function () {
    document.documentElement.scrollTop = 0
  },
  methods: {
    backToHistory: function () {
      this.$router.push({ name: 'History' })
    },
    getMoment: function (timeString) {
      return moment.tz(timeString, 'YYYYMMDDHH', 'Etc/GMT-8')
    },
    getSolarHour: function (hour) {
      let str
      if (0 === hour || 23 === hour) {
        str = hour + ':00 ~ ' + hour + ':59'
      } else {
        str = hour + ':00 ~ ' + (hour + 1) + ':59'
      }
      return str
    },
    getLunarHour: function (hour) {
      if (hour !== 0 && hour % 2 === 0) {
        hour--
      }
      return SHI_CHEN[hour]
    },
    formatDateRange: function (dateRange) {
      if (Array.isArray(dateRange)) {
        return [...dateRange].reverse().map(this.formatDateRange).join(', ')
      }
      return `${moment(dateRange.startSolarDate, 'YYYYMMDD').format(
        'MM/DD'
      )} - ${moment(dateRange.endSolarDate, 'YYYYMMDD').format('MM/DD')}`
    },
    monthlyId: function (month) {
      return 'accordion' + month
    },
    toggleTag: function () {
      this.activeLeft = !this.activeLeft
    },
  },
  computed: {
    ...mapGetters('fortune', {
      search: 'search',
      isLoading: 'isLoading',
    }),
    orderNo: function () {
      return this.$route.params.orderNo
    },
    cards: function () {
      var subStr = this.result.xingZuo.substr(2, 2)
      if (subStr == '') return [this.result.xingZuo]
      else
        return [
          this.result.xingZuo.substr(0, 2),
          this.result.xingZuo.substr(2, 2),
        ]
    },
  },
}
</script>

<!-- <style src="@/assets/css/2022style.css" scoped></style> -->
<style scoped>
h2,
p {
  margin: 0;
}
.nav {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  font-size: 14px;
}
.nav h2 {
  line-height: inherit;
  font-size: 1.5em;
  font-weight: bold;
}
.back {
  position: absolute;
  left: 3%;
  cursor: pointer;
}
.re_container {
  padding-top: 0;
}
.heading5 {
  font-size: 21px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0;
}
.paragraph {
  font-size: 16px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0;
}
.text-buy {
  color: #ef3900;
}
.ct-series-a .ct-line {
  stroke: #eea12e;
  stroke-width: 3px;
}

.ct-series-a .ct-point {
  stroke: #eea12e;
}
.keyMonth-content-subtitle {
  display: block;
}

.blurarea {
  display: none;
}

.resultSection1-info h6 {
  padding: 20px 0 20px 0;
}
.result-view {
  background-color: #f3efed;
}

@media (max-width: 576px) {
  .heading5 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0;
  }
}
</style>
